<template>
	<div id="app">
		<Navbar></Navbar>
		<router-view class="view"></router-view>
	</div>
</template>

<script>

import Navbar from "@/components/Navbar.vue";

export default {
	name: 'App',
	components: {Navbar}
}
</script>

<style>
#app {
	background-image: url("@/assets/img/robot_bg.png");
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
}
</style>
