import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '',
		component: () => import(/* webpackChunkName: "home" */ '../layouts/DefaultLayout.vue'),
		children: [
			{
				path: '/',
				name: 'Home',
				component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
			},
			{
				path: '/terms-conditions',
				name: 'Terms & Conditions',
				component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/TermsConditions.vue')
			},
			{
				path: '/privacy-policy',
				name: 'Privacy Policy',
				component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/PrivacyPolicy.vue')
			},
			{
				path: 'tutorials',
				name: 'Tutorials',
				component: () => import(/* webpackChunkName: "bot" */ '../views/TutorialView.vue'),
			},
			{
				path: 'points',
				name: 'Points',
				component: () => import(/* webpackChunkName: "bot" */ '../views/points/LeaderboardView.vue'),
			},
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {top: 0}
	},
	routes
})

export default router
