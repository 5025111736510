<template>
	<nav class="navbar navbar-expand-lg navbar-dark bg-dark gradient-primary py-1">
		<div class="container-fluid container-xl">
			<router-link class="navbar-brand py-0" to="/">
				<img src="/logo.png" height="64px" class="me-3">
				<span class="logo-name">FluxBot</span>
			</router-link>
			<button id="toggle" class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
				<ul class="navbar-nav mx-auto">
					<li class="nav-item d-flex align-items-center">
						<router-link class="nav-link" to="/"><span>HOME</span></router-link>
					</li>
					<li class="nav-item d-flex align-items-center">
						<router-link class="nav-link" to="/points"><span>POINTS</span></router-link>
					</li>
					<li class="nav-item d-flex align-items-center">
						<router-link class="nav-link" to="/tutorials"><span>TUTORIALS</span></router-link>
					</li>
					<li class="nav-item d-flex align-items-center">
						<a class="nav-link" href="https://docs.fluxbot.xyz" target="_blank"><span>DOCS</span></a>
					</li>
				</ul>

				<ul class="navbar-nav ms-auto mb-lg-0">
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: "Navbar",
	data() {
		return {
			interval: null
		}
	},
	methods: {
		closeNav: function () {
			document.querySelector("#toggle").classList.toggle("collapsed")
			const content = document.querySelector("#navbarSupportedContent")
			content.classList.toggle("collapsing")
			content.classList.remove("show")
		},
		isDevice: function () {
			return window.innerWidth < 992
		}
	},
	beforeRouteUpdate() {
		this.closeNav()
	}
}
</script>

<style scoped>
</style>